@keyframes container-rotate {
	to {
		transform: rotate(360deg)
	}
}

@keyframes fill-unfill-rotate {
  12.5% {
		transform: rotate(135deg);
  }

  25% {
		transform: rotate(270deg);
  }

  37.5% {
		transform: rotate(405deg);
  }

  50% {
		transform: rotate(540deg);
  }

  62.5% {
		transform: rotate(675deg);
  }

  75% {
		transform: rotate(810deg);
  }

  87.5% {
		transform: rotate(945deg);
  }

  to {
		transform: rotate(1080deg);
  }
}

@keyframes left-spin {
  from {
    transform: rotate(130deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  to {
    transform: rotate(130deg);
  }
}

@keyframes right-spin {
  from {
		transform: rotate(-130deg);
  }

  50% {
    transform: rotate(5deg);
  }

  to {
    transform: rotate(-130deg);
  }
}

.loader {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;	
}

.spinner-wrapper {
	display: inline-block;
	position: relative;
	width: 6rem;
	height: 6rem;
	font-size: 0;

	&.active {
		animation: container-rotate 1568ms linear infinite;

		.spinner-layer {
			opacity: 1;
			animation: fill-unfill-rotate 5332ms cubic-bezier(.4,0,.2,1) infinite both;
		}

		.circle-clipper {
			&.left {
				.circle {
					animation: left-spin 1333ms cubic-bezier(.4,0,.2,1) infinite both;
				}
			}

			&.right {
				.circle {
					animation: right-spin 1333ms cubic-bezier(.4,0,.2,1) infinite both;
				}
			}
		}
	}

	.spinner-layer {
		border-color: #e36d1e;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;

		.circle-clipper {
	    display: inline-block;
	    position: relative;
	    width: 50%;
	    height: 100%;
	    overflow: hidden;
	    border-color: inherit;

			&.left {
				.circle {
					left: 0;
					border-right-color: transparent!important;
					transform: rotate(129deg);
				}
			}

			&.right {
				.circle {
					left: -100%;
					border-left-color: transparent!important;
					transform: rotate(-129deg);
				}
			}

			.circle {
				width: 200%;
		    height: 100%;
		    border-width: .1875rem;
		    border-style: solid;
		    border-color: inherit;
		    border-bottom-color: transparent!important;
		    -webkit-border-radius: 50%;
		    border-radius: 50%;
		    -webkit-animation: none;
		    animation: none;
		    position: absolute;
		    top: 0;
		    right: 0;
		    bottom: 0;
			}
		}

		.gap-patch {
	    position: absolute;
	    top: 0;
	    left: 45%;
	    width: 10%;
	    height: 100%;
	    overflow: hidden;
	    border-color: inherit;

			.circle {
				width: 1000%;
		    left: -450%;
			}
		}
	}
}