@import '../../assets/scss/variable';
@import '../../assets/scss/mixins';

.nav-bar {
  flex-basis: 33%;

  .hamburger {
    left: 20px;
    background-color: transparent;
    border: 0;
    border-top: 1px solid $black-color;
    cursor: pointer;
    display: inline-block;
    font-size: 0;
    height: 11px;
    line-height: 14px;
    margin: 20px 0 23px;
    min-height: auto;
    outline: none;
    padding: 0;
    position: relative;
    width: 17px;

    &::after {
      border-top: 1px solid $black-color;
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
}


.side-menu {

  &-left {
    display: inline-block;
    padding-top: 6px;
    vertical-align: middle;

    .main-menu {
      .open & {
        display: block;
      }

      .main-menu__list {
        @include ul-li-clear-list;
        padding: 25px 0 0;

        .main-menu__item {
          @include media(null, $bp-medium) {
            padding: 5px 10px;
          }

          border-bottom: 1px solid $black-color;
          display: block;
          text-transform: uppercase;
          @include font(18, 500);
          padding: 5px;
          background: $white-color;

          &:last-child {
            border-bottom: 0;
          }
        }

        &__link {
          @include media(null, $bp-medium) {
            letter-spacing: 1px;
            text-transform: uppercase;

            &::before {
              @include font(14, 400);
              content: '\f105';
              font-family: $global-font-family;
              padding-right: 5px;
            }

            &.active {
              &::before {
                content: '\f104';
              }
            }
          }

          cursor: pointer;

          &::before {
            @include font(14, 400);
            content: '\f105';
            font-family: $global-font-family;
            padding-right: 5px;
          }
        }
      }
    }
  }
}