@keyframes dot-keyframes {
  0% {
    background-size: 10% 100%;
  }

  50% {
    background-size: 50% 100%;
  }

  100% {
    background-size: 100% 100%;
  }
}

.loading-dots {
  animation: dot-keyframes 4s infinite ease-in-out;
  background-image: url('../../assets/images/loading_bar.gif');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 2px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}