@import './variable';
@import './mixins';

.side-menu-left {
  background-color: $white-color;
  height: 100%;
  left: -321px;
  overflow-y: auto;
  position: fixed;
  top: 0;
  transition: all .5s;
  width: 321px;
  z-index: 2;
}

.open {
  .side-menu-left {
    left: 0px;
  }
}

.overlay {
  .open & {
    background-color: $black-rgba-50;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
}