html {
  font-size: 1rem;
  font-weight: normal;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  line-height: 20px; }

body {
  font-size: 0.75rem;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  height: 100%;
  line-height: 20px;
  margin: 0; }

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  word-break: break-word; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  word-break: break-word; }

h1 {
  font-size: 2rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 40px; }
  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 32px; } }

h2 {
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 32px; }
  @media only screen and (max-width: 767px) {
    h2 {
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 32px; } }

h3 {
  font-size: 1.25rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 32px; }
  @media only screen and (max-width: 767px) {
    h3 {
      font-size: 1rem;
      font-weight: 400;
      line-height: 24px; } }

h4 {
  font-size: 1rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 24px; }
  @media only screen and (max-width: 767px) {
    h4 {
      font-size: 0.625rem;
      font-weight: 400;
      font-family: "Roboto", sans-serif;
      line-height: 16px; } }

h5 {
  font-size: 0.625rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 16px;
  text-transform: uppercase; }

h6 {
  font-size: 0.625rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 16px;
  text-transform: uppercase; }

a {
  color: #1c1c1c;
  cursor: pointer;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

img {
  max-width: 100%; }

.disabled {
  opacity: .5;
  pointer-events: none; }

[hidden] {
  display: none; }

[disabled] {
  opacity: .5;
  pointer-events: none; }

p {
  font-size: 0.75rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 20px;
  margin-top: 0;
  word-break: break-word; }

body > div {
  height: 100%; }

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%; }

.body-container {
  flex-grow: 1; }

.footer,
.header {
  flex-shrink: 0; }

.popup-overlay {
  background-color: rgba(28, 28, 28, 0.1);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2; }
  .open-overlay .popup-overlay {
    display: block; }

input,
select {
  font-family: "Roboto", sans-serif; }

.clearfix::after {
  clear: both;
  content: '';
  display: table;
  overflow: hidden; }

* {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  font-size: 14px; }

img {
  max-width: 100%; }

body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  width: 100% !important;
  height: 100%;
  line-height: 1.6em; }

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%; }
  .page.page-body {
    padding-bottom: 100px;
    padding-top: 100px; }

.body-container {
  flex-grow: 1; }

.padding-lr {
  padding-right: 15px;
  padding-left: 15px; }

@media (min-width: 1024px) {
  .container.alt {
    max-width: 1200px; } }

.marginbottom-lg {
  margin-bottom: 30px !important; }

.margintop-xlg {
  margin-top: 60px !important; }

@keyframes container-rotate {
  to {
    transform: rotate(360deg); } }

@keyframes fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg); }
  25% {
    transform: rotate(270deg); }
  37.5% {
    transform: rotate(405deg); }
  50% {
    transform: rotate(540deg); }
  62.5% {
    transform: rotate(675deg); }
  75% {
    transform: rotate(810deg); }
  87.5% {
    transform: rotate(945deg); }
  to {
    transform: rotate(1080deg); } }

@keyframes left-spin {
  from {
    transform: rotate(130deg); }
  50% {
    transform: rotate(-5deg); }
  to {
    transform: rotate(130deg); } }

@keyframes right-spin {
  from {
    transform: rotate(-130deg); }
  50% {
    transform: rotate(5deg); }
  to {
    transform: rotate(-130deg); } }

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; }

.spinner-wrapper {
  display: inline-block;
  position: relative;
  width: 6rem;
  height: 6rem;
  font-size: 0; }
  .spinner-wrapper.active {
    animation: container-rotate 1568ms linear infinite; }
    .spinner-wrapper.active .spinner-layer {
      opacity: 1;
      animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }
    .spinner-wrapper.active .circle-clipper.left .circle {
      animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }
    .spinner-wrapper.active .circle-clipper.right .circle {
      animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }
  .spinner-wrapper .spinner-layer {
    border-color: #e36d1e;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0; }
    .spinner-wrapper .spinner-layer .circle-clipper {
      display: inline-block;
      position: relative;
      width: 50%;
      height: 100%;
      overflow: hidden;
      border-color: inherit; }
      .spinner-wrapper .spinner-layer .circle-clipper.left .circle {
        left: 0;
        border-right-color: transparent !important;
        transform: rotate(129deg); }
      .spinner-wrapper .spinner-layer .circle-clipper.right .circle {
        left: -100%;
        border-left-color: transparent !important;
        transform: rotate(-129deg); }
      .spinner-wrapper .spinner-layer .circle-clipper .circle {
        width: 200%;
        height: 100%;
        border-width: .1875rem;
        border-style: solid;
        border-color: inherit;
        border-bottom-color: transparent !important;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        -webkit-animation: none;
        animation: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0; }
    .spinner-wrapper .spinner-layer .gap-patch {
      position: absolute;
      top: 0;
      left: 45%;
      width: 10%;
      height: 100%;
      overflow: hidden;
      border-color: inherit; }
      .spinner-wrapper .spinner-layer .gap-patch .circle {
        width: 1000%;
        left: -450%; }

@media screen and (max-width: 360px) {
  .hide-xxs {
    display: none !important; }
  .show-xxs {
    display: block !important; } }

@media screen and (max-width: 400px) {
  .hide-xs {
    display: none !important; }
  .show-xs {
    display: block !important; } }

@media screen and (max-width: 567px) {
  .hide-sm {
    display: none !important; }
  .show-sm {
    display: block !important; } }

@media screen and (max-width: 768px) {
  .hide-sm {
    display: none !important; }
  .show-sm {
    display: block !important; } }

@media screen and (max-width: 992px) {
  .hide-md {
    display: none !important; }
  .show-md {
    display: block !important; } }

@media screen and (max-width: 1440) {
  .hide-lg {
    display: none !important; }
  .show-lg {
    display: block !important; } }

.modal, .modal-open {
  overflow: hidden; }

.new-modal {
  z-index: 5011 !important;
  font-family: proxima-nova,sans-serif;
  box-shadow: none; }
  .new-modal .modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    margin: 0 auto;
    border-radius: 10px; }
  .new-modal .modal-action-btn {
    position: absolute;
    top: 40px;
    background: transparent;
    color: #fff; }
    .new-modal .modal-action-btn.action-right {
      right: 20px; }
  .new-modal::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none; }
  .new-modal .new-modal-body .modal-title {
    font-size: 30px;
    color: #737373;
    font-weight: 700;
    line-height: 1; }
  .new-modal .modal-header .modal-subtitle-foreign {
    padding: 10px 20px;
    font-weight: 400;
    font-size: 14px;
    margin-top: 0; }

.full-page-modal {
  overflow: auto;
  padding-right: 0;
  z-index: 100000000;
  overflow: scroll !important;
  max-height: 100vh !important;
  height: 100%; }

.modal-action-btn {
  appearance: none;
  position: fixed;
  top: 40px;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border-radius: 50%;
  border: none;
  background: var(--theme-primary-bg);
  color: var(--theme-primary);
  text-shadow: none;
  opacity: 1;
  z-index: 11;
  transition: all .15s linear; }
  .modal-action-btn.action-right {
    right: 50px; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 18;
  display: none;
  -webkit-overflow-scrolling: touch;
  outline: 0; }
  .modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translateY(-25%); }
  .modal.in .modal-dialog {
    transform: translate(0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5); }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 17;
  background-color: #000; }
  .modal-backdrop.fade {
    filter: alpha(opacity=0);
    opacity: 0; }
  .modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: 0.5; }

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5; }
  .modal-header .close {
    margin-top: -2px; }

.modal-title {
  margin: 0;
  line-height: 1.42857143; }

.modal-body {
  position: relative;
  padding: 15px; }

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5; }
  .modal-footer .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px; }
  .modal-footer .btn-group .btn + .btn {
    margin-left: -1px; }
  .modal-footer .btn-block + .btn-block {
    margin-left: 0; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto; }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }
  .modal-sm {
    width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    width: 900px; } }

.page__container {
  margin: 0 auto;
  max-width: 1212px;
  padding: 0 10px; }
  .page__container .wrapper {
    margin: 0 auto;
    max-width: 380px;
    padding: 40px 0 30px; }
    .page__container .wrapper .tut-info-title {
      margin-bottom: 0px; }
    .page__container .wrapper .message-error {
      margin-bottom: 10px;
      padding: 10px;
      font-size: 0.75rem;
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      background-color: #ed6006; }
    .page__container .wrapper .message-success {
      margin-bottom: 10px;
      padding: 10px;
      font-family: "Roboto", sans-serif;
      font-size: 0.75rem;
      font-weight: 600;
      background-color: #01bbd2; }
    .page__container .wrapper .errorMsg {
      font-size: 0.75rem;
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      color: #ed6006; }
    .page__container .wrapper .form-label {
      font-family: "Roboto", sans-serif;
      margin-bottom: 20px;
      display: block; }

.auth-options {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 0;
  padding: 0px;
  margin-top: 10px; }
  .auth-options__item {
    text-align: center;
    display: block;
    font-family: "HelveticaNeuebd", Arial, "Lucida Grande", sans-serif;
    font-weight: 600;
    padding-bottom: 10px; }
    .auth-options__item-forgetPass {
      color: black;
      font-family: "HelveticaNeuebd", Arial, "Lucida Grande", sans-serif;
      font-size: 0.8rem;
      text-decoration: none; }
    .auth-options__item-login {
      color: black;
      font-family: "HelveticaNeuebd", Arial, "Lucida Grande", sans-serif;
      font-size: 0.8rem;
      text-decoration: none; }
    .auth-options__item-register {
      color: black;
      font-family: "HelveticaNeuebd", Arial, "Lucida Grande", sans-serif;
      font-size: 0.8rem;
      text-decoration: none; }

button,
.button {
  font-size: 0.75rem;
  font-weight: 400;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  line-height: 20px;
  min-height: 40px;
  padding: 0 16px; }
  button.add-tutoial,
  .button.add-tutoial {
    font-size: 1.125rem;
    font-weight: 200;
    background: #03bbd2;
    color: white; }
    @media only screen and (min-width: 990px) and (max-width: 1024px) {
      button.add-tutoial,
      .button.add-tutoial {
        padding: 0px 8px; } }
    @media only screen and (max-width: 990px) {
      button.add-tutoial,
      .button.add-tutoial {
        padding: 0px 16px; } }

.side-menu-left {
  background-color: #fff;
  height: 100%;
  left: -321px;
  overflow-y: auto;
  position: fixed;
  top: 0;
  transition: all .5s;
  width: 321px;
  z-index: 2; }

.open .side-menu-left {
  left: 0px; }

.open .overlay {
  background-color: rgba(28, 28, 28, 0.1);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%; }

* {
  box-sizing: border-box; }

html body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  top: 0px;
  left: 0px; }

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
  font-weight: 400;
  color: #383838;
  background-color: #fafafa;
  opacity: 1; }

.footer-links {
  list-style: none;
  margin: 0px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 15px; }
  .footer-links .footer-link a {
    text-decoration: none;
    margin: 15px;
    color: #ffffff;
    padding: 10px;
    text-decoration: none;
    font-weight: bold; }
    .footer-links .footer-link a:hover, .footer-links .footer-link a:visited, .footer-links .footer-link a:focus {
      color: #ffffff;
      border-bottom: 5px solid white; }
