@import 'mixins';
@import 'variable';

html {
  @include font($base-size, normal);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  line-height: 20px;
}

body {
  @include font(12, 400);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: $white-color;
  color: $black-color;
  font-family: $global-font-family;
  height: 100%;
  line-height: 20px;
  margin: 0;
}

*,
*::after,
*::before {
  @include box-sizing(border-box);
  outline: none;
  word-break: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  word-break: break-word;
}

h1 {
  @include media(null, $bp-medium) {
    @include font(24, 400);
    line-height: 32px;
  }

  @include font(32, 400);
  font-family: $global-font-family;
  line-height: 40px;
}

h2 {
  @include media(null, $bp-medium) {
    @include font(20, 400);
    line-height: 32px;
  }

  @include font(24, 400);
  font-family: $global-font-family;
  line-height: 32px;
}

h3 {
  @include media(null, $bp-medium) {
    @include font(16, 400);
    line-height: 24px;
  }

  @include font(20, 400);
  font-family: $global-font-family;
  line-height: 32px;
}

h4 {
  @include media(null, $bp-medium) {
    @include font(10, 400);
    font-family: $global-font-family;
    line-height: 16px;
  }

  @include font(16, 400);
  font-family: $global-font-family;
  line-height: 24px;
}

h5 {
  @include font(10, 600);
  font-family: $global-font-family;
  line-height: 16px;
  text-transform: uppercase;
}

h6 {
  @include font(10, 600);
  font-family: $global-font-family;
  line-height: 16px;
  text-transform: uppercase;
}

a {
  color: $black-color;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
}

// Default Classes
.disabled {
  opacity: .5;
  pointer-events: none;
}

// hidden attribute
[hidden] {
  display: none;
}

// disabled attribute
[disabled] {
  opacity: .5;
  pointer-events: none;
}

p {
  @include font(12, 400);
  font-family: $global-font-family;
  line-height: 20px;
  margin-top: 0;
  word-break: break-word;
}

// sticky footer
body>div {
  height: 100%;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.body-container {
  flex-grow: 1;
}

.footer,
.header {
  flex-shrink: 0;
}

.popup-overlay {
  background-color: $black-rgba-50;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  .open-overlay & {
    display: block;
  }
}

input,
select {
  font-family: $global-font-family;
}

.clearfix {
  &::after {
    clear: both;
    content: '';
    display: table;
    overflow: hidden;
  }
}