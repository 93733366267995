footer {
  display: block;
  background: #FFFFFF;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px 0;
  z-index: 1;
  width: 100%;
  width: 100%;
}

.container.alt {
  max-width: 1200px;
}

footer {
  bottom: 0px;
  left: 0px;
  right: 0px;
  .footer-holder {
    display: flex;
    align-items: center;
  }

  .links {
    flex: 1;

    ul {
      display: flex;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding-left: 0;

      li {
        padding: 0 10px;

        a {
          text-decoration: none;
          color: #7b7b7b;
        }
      }
    }
  }
}